/* Table.css */
.table-container {
    padding: 0px !important;
    width: 100%;
   
    overflow-x: auto; /* Enable horizontal scroll on small screens */
    display: flex;
    justify-content: center;
}
@font-face {
    font-family: 'poppin-light';
    src: url("../fonts/Poppins-Light.otf") format('truetype');
}

.MuiDataGrid-root {
    width: 100%; 
    font-family: 'poppin-light' !important;
}

/* Adjust styles for small screens */
@media only screen and (max-width: 768px) {
    .MuiDataGrid-root {
        font-size: 0.8rem; /* Reduce font size */
    }
    
    .MuiDataGrid-cell, .MuiDataGrid-columnHeader {
        padding-left: 8px;
        padding-right: 8px;
    }

    .MuiDataGrid-columnHeaderTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; /* Prevent text wrapping */
    }
}

.pagination{
    display: flex;
    align-items: center;
    justify-content: end;
}

