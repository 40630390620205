@font-face {
    font-family: 'poppin-bold';
    src: url("../src/assets/fonts/Poppins-Bold.otf") format('truetype');
}

@font-face {
    font-family: 'poppin-light';
    src: url("../src/assets/fonts/Poppins-Light.otf") format('truetype');
}

.btn {
    font-family: "poppin-light" !important;
    text-transform: none !important;
    border-radius: 18px !important;
    /* background-color: #ff5555 !important; */
    background-color: #32328E !important;
    color: white !important;
}
.grey-btn {
    font-family: "poppin-light" !important;
    text-transform: none !important;
    border-radius: 18px !important;
    /* background-color: #ff5555 !important; */
    background-color: grey !important;
    color: white !important;
}

.w-100 {
    width: 100% !important;
}

body {
    font-family: 'poppin-light' !important;
}

.mob-btn {
    display: none !important;
}

.offcanvas-header {
    justify-content: end !important;
    border: none !important;
    background-color: #ff6b0175;
    color: black;
    height: 3vh;
}

.justify {
    justify-content: space-between;
    align-items: center;
}

/* .main {
    padding: 15px;
} */

.align-center {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.link {
    text-decoration: none !important;
}

.sidebar_layout {
    width: 23%;
    padding: 0px !important;
}

.main_layout {
    width: 77%;
}

.side-card {
    /* background-color: #ff000029 !important; */
    /* color: #ff5555; */
    background-color: #ced8e1 !important;
    /* height: 100vh; */
    overflow: scroll;
    position: relative;
    top: 0;
    bottom: 0;
    width: 23%;
    position: fixed;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.admin_text {
    color: #000000;
    font-size: 15px;
    text-align: left;
    font-weight: 700;
}

.bann-img {
    height: 210px;
    width: 100%;
}

.form-control:disabled {
    background-color: #ced8e1;
    cursor: not-allowed;
    width: 100%;
}

.cms-ban-img {
    height: 180px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .mob-nav {
        display: none !important;
    }

    .web-btn {
        display: none !important;
    }

    .mob-btn {
        display: block !important;
    }

    .main_layout {
        width: 100% !important;
    }

    .side-card {
        width: 100% !important;
    }

    .side-card {
        box-shadow: unset !important;
        margin-top: -11%;
    }

    .offcanvas-header {
        background-color: #c9919100;
        height: 3vh;
        z-index: 999;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
        width: 19px !important;
        height: 19px !important;
        color: grey !important;
        margin-top: 1%;
    }
    .main {
        padding: 10px !important;
    }
}

.nav-link {
    color: #6e7881;
    cursor: pointer;
    align-items: center;
}

.nav-link:hover {
    /* color: #ff5555; */
    color: #32328E;
}

.active-link-text {
    /* color: #ff5555; */
    color: #32328E;
    background-color: white;
    border-radius: 4px;

}
.cms-active{
    color: #32328E;
}

p {
    margin-bottom: 0px;
}

.dashboard_card {
    background-color: #ffff;
    height: 100vh;
    overflow-y: scroll !important;
    /* padding-left: 0px; */
}

.dash_text {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px !important;
    margin-bottom: 0px !important;
}

.dash_count {
    /* background-color: #ff5555 !important; */
    background-color: #32328E !important;
}

.dash_count_text {
    color: #fff;
}

.swal2-confirm {
    /* background-color: #ff5555 !important; */
    background-color: #32328E !important;
}

.logout_dropdown {
    margin-right: 2% !important;
    display: flex;
    flex-direction: row;
}

.dropbtn {
    border: none;
    border-radius: 50% !important;
}

.dropdown_component {
    position: relative;
    display: inline-block;
}

.dropdown_component img {
    object-fit: contain;
    border-radius: 50%;
    width: 30px;
    height: 35px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 1px !important;

}

.dropdown-content p {
    font-size: 13px;
    color: #32328E;
    padding: 6px 8px;
    text-decoration: none !important;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown_component:hover .dropdown-content {
    display: block;
}

/* status */
.status_form {
    display: flex;
    flex-direction: column;
}

.css-de9k3v-MuiDataGrid-selectedRowCount {
    color: transparent !important;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    /* background-color: #ff5555 !important; */
    background-color: #32328E !important;
    color: white;
}

.css-k008qs {
    background-color: #32328E !important;
    color: white;
}
.css-1ve3l6o .MuiDataGrid-container--top{
    background-color: #32328E !important;
    color: white; 
}
#custom-scroll{
    overflow-y: scroll !important;
}
#custom-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    border-radius: 10px !important;
    background-color: #F5F5F5 !important;
}

#custom-scroll::-webkit-scrollbar {
    width: 3px !important;
    background-color: #F5F5F5 !important;
}